body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mode {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
}

.tracks,
.concerts,
.end {
  width: 50vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .tracks,
  .concerts {
    width: 100vw;
    height: 50vh;
  }
}

.tracks {
  background-image: url(../../static/media/stock1.98617699.jpg);
}

.concerts {
  background-image: url(../../static/media/stock2.7eee9e95.jpg);
}

.end {
  width: 100vw;
  background-image: url(../../static/media/stock2.7eee9e95.jpg);
}

.tracks .header,
.concerts .header,
.end .header {
  position: absolute;
  top: calc(50% - 32px);
  left: 0;
  right: 0;
  font-size: 3em;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 12px #000;
  text-align: center;
}

.player {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
}

.playlistview {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #000;
  padding: 10px;
}

.playlistitem {
  width: calc(100% / 4);
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .playlistitem {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 768px) {
  .playlistitem {
    width: calc(100% / 2);
  }
}

.playlistitem a {
  text-decoration: none;
}

.playlistitem img {
  max-width: 100%;
}

.playlistitemtitle {
  color: #fff;
  font-size: .875em;
}

.getrandom {
  position: absolute;
  color: #fff;
  z-index: 9;
  bottom: 20px;
  left: 120px;
  right: 120px;
  text-align: center;
}

.button {
  display: inline-block;
  background: #76B3FA;
  border-radius: 40px;
  padding: 8px 40px 10px;
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  opacity: .75;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}

@media screen and (max-width: 480px) {
  .getrandom {
    left: 20px;
    right: 20px;
  }

  .button {
    display: block;
  }
}

.button:hover {
  opacity: 1;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}

.nowplaying {
  position: absolute;
  z-index: 9;
  top: 20px;
  left: 20px;
  right: 20px;
}

.nowplayingwrapper {
  display: block;
  background: #2a2a2a;
  border-radius: 20px;
  padding: 4px 60px 6px 20px;
  position: relative;
  max-width: 640px;
  margin: 0 auto;
}

.nowplayingwrapper:hover .recentlyplayed {
  display: block;
  color: #fff;
}

.toggleicon {
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  font-size: 1.25em;
  opacity: .65;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}

.title {
  display: block;
  padding: 6px 0 6px 24px;
  text-align: left;
  position: relative;
  opacity: .65;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}

.title a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  font-size: .875em;
  font-weight: bold;
}

.title:hover {
  opacity: 1;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}

.icon {
  top: 9px;
  left: 0;
  position: absolute;
}
